<template>
  <div class="matchPo">
    <Header
      back="返回"
      title="匹配工具"
      index="首页"
      titleOne="智能匹配"
      beforeTitle="匹配工具"
    />
    <div class="content">
      <h1>政策条件信息</h1>
      <el-form
        ref="form"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="政策名称" style="width: 100%">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="客户来源类型">
          <el-select v-model="form.region" placeholder="请选择类型">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="活动性质" style="width: 100%">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="条件1" name="1"></el-checkbox>
            <el-checkbox label="条件2" name="2"></el-checkbox>
            <el-checkbox label="条件3" name="3"></el-checkbox>
            <el-checkbox label="条件4" name="type"></el-checkbox>
            <el-checkbox label="条件5" name="type"></el-checkbox>
            <el-checkbox label="条件6" name="type"></el-checkbox>
            <el-checkbox label="条件7" name="type"></el-checkbox>
            <el-checkbox label="条件8" name="type"></el-checkbox>
            <el-checkbox label="条件9" name="type"></el-checkbox>
            <el-checkbox label="条件10" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="其他条件1">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他条件2">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他条件3">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他条件4">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width: 100%">
          <div class="foot_TJ">
            <el-button type="primary" @click="onSubmit">开始匹配</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelPosition: "right",
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style>
.matchPo .el-form-item__label {
  width: 100% !important;
  text-align: left;
}

.matchPo .el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.matchPo .el-select {
  width: 100%;
}

.matchPo .el-checkbox {
  float: left;
  color: #909399;
  width: 10%;
  margin-right: 0 !important;
}
.matchPo .el-radio-group {
  float: right;
}
.matchPo h5 {
  font-size: 16px;
  color: #cbcfe3;
  font-weight: 400;
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
</style>
